.colored-rectangle.svelte-1w8btj1{width:75%;margin:100px auto;padding:30px;background-color:rgba(
      215,
      181,
      148,
      0.9
    );border:5px solid rgba(188, 151, 96, 1);min-height:80%;border-radius:15px;font-family:"Acme", sans-serif;box-shadow:0 4px 20px rgba(0, 0, 0, 0.7);background-image:url("/media/images/papertexture.jpeg");background-size:cover;background-position:center 10%;position:relative}.colored-rectangle.svelte-1w8btj1::before{content:"";position:absolute;top:0;left:0;right:0;bottom:0;background-color:rgba(
      215,
      181,
      148,
      0.365
    );border-radius:15px;z-index:0}.article-header.svelte-1w8btj1,.article-date.svelte-1w8btj1,.article-category-container.svelte-1w8btj1,.article-category.svelte-1w8btj1,.article-image.svelte-1w8btj1,.article-text.svelte-1w8btj1{position:relative;z-index:1}.article-category-container.svelte-1w8btj1{background-color:#ff7a00;color:#fff;border-radius:8px;text-align:center;padding:5px;max-width:80%;margin:10px auto;box-shadow:0 2px 5px rgba(0, 0, 0, 0.3);position:relative;z-index:1}.article-category.svelte-1w8btj1{font-size:18px;text-transform:uppercase;font-weight:bold}.world.svelte-1w8btj1{background-color:rgb(102, 87, 0)}.lore.svelte-1w8btj1{background-color:rgb(1, 41, 114)}.games.svelte-1w8btj1{background-color:rgb(0, 71, 0)}.streams.svelte-1w8btj1{background-color:rgb(91, 91, 91)}.novels.svelte-1w8btj1{background-color:purple}.personal.svelte-1w8btj1{background-color:rgb(123, 0, 0)}.article-header.svelte-1w8btj1{text-align:center;font-size:42px;margin-top:50px;margin-bottom:5px;color:#e0b0ff;font-weight:bold;text-shadow:2px 2px 4px rgba(0, 0, 0, 0.8),
      1px 1px 2px rgba(162, 0, 255, 0.6)}.article-date.svelte-1w8btj1{text-align:center;font-size:18px;color:#e0b0ff;margin-top:10px;font-weight:500;text-shadow:1px 1px 3px rgba(0, 0, 0, 0.5),
      1px 1px 2px rgba(162, 0, 255, 0.4)}.article-image.svelte-1w8btj1{width:80%;margin:20px auto;max-height:300px}.article-text.svelte-1w8btj1{font-size:16px;margin-top:10px;color:#333}@media(max-width: 768px){.colored-rectangle.svelte-1w8btj1{width:90%}.article-header.svelte-1w8btj1{font-size:20px;margin-top:20px}.article-date.svelte-1w8btj1{font-size:12px}.article-text.svelte-1w8btj1{font-size:14px}.article-image.svelte-1w8btj1{width:100%;margin:10px auto}}