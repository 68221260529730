.colored-rectangle.svelte-gk0oem{width:75%;margin:100px auto;padding:30px;background-color:rgba(
      215,
      181,
      148,
      0.9
    );border:5px solid rgba(188, 151, 96, 1);min-height:80%;border-radius:15px;font-family:"Acme", sans-serif;box-shadow:0 4px 20px rgba(0, 0, 0, 0.7);background-image:url("/media/images/papertexture.jpeg");background-size:cover;background-position:center 10%;position:relative}.colored-rectangle.svelte-gk0oem::before{content:"";position:absolute;top:0;left:0;right:0;bottom:0;background-color:rgba(
      215,
      181,
      148,
      0.365
    );border-radius:15px;z-index:0}.story-header.svelte-gk0oem,.story-date.svelte-gk0oem,.story-category-container.svelte-gk0oem,.story-category.svelte-gk0oem,.story-text.svelte-gk0oem{position:relative;z-index:1}.story-category-container.svelte-gk0oem{background-color:#ff7a00;color:#fff;border-radius:8px;text-align:center;padding:5px;max-width:80%;margin:10px auto;box-shadow:0 2px 5px rgba(0, 0, 0, 0.3)}.story-category.svelte-gk0oem{font-size:18px;text-transform:uppercase;font-weight:bold}.story-header.svelte-gk0oem,.story-date.svelte-gk0oem{text-align:center}.story-header.svelte-gk0oem{font-size:42px;margin-top:50px;margin-bottom:5px;color:#e0b0ff;font-weight:bold;text-shadow:2px 2px 4px rgba(0, 0, 0, 0.6),
      1px 1px 2px rgba(162, 0, 255, 0.6)}.story-date.svelte-gk0oem{font-size:18px;color:#e0b0ff;margin-top:10px;font-weight:500;text-shadow:1px 1px 3px rgba(0, 0, 0, 0.4),
      1px 1px 2px rgba(162, 0, 255, 0.4)}.story-text.svelte-gk0oem{font-size:16px;margin-top:10px;color:#333}@media(max-width: 768px){.colored-rectangle.svelte-gk0oem{width:90%}.story-header.svelte-gk0oem{font-size:20px}.story-date.svelte-gk0oem{font-size:12px}.story-text.svelte-gk0oem{font-size:14px}}