.colored-rectangle.svelte-jjr1wa.svelte-jjr1wa{width:85%;margin:100px auto;padding:30px;background-color:rgba(
      215,
      181,
      148,
      0.9
    );border:5px solid rgba(188, 151, 96, 1);min-height:80%;border-radius:15px;font-family:"Acme", sans-serif;color:#333;line-height:1.5;box-shadow:0 4px 20px rgba(0, 0, 0, 0.7);overflow:hidden;background-image:url("https://res.cloudinary.com/dw39fffyv/image/upload/v1742167495/papertexture_we5bse.jpg");background-size:cover;background-position:center}.colored-rectangle.svelte-jjr1wa p.svelte-jjr1wa{color:#210742}.row.svelte-jjr1wa.svelte-jjr1wa{display:flex;margin-top:50px;margin-bottom:10px;align-items:center}.row-container.svelte-jjr1wa.svelte-jjr1wa{width:33.33%}.section-title.svelte-jjr1wa.svelte-jjr1wa{font-family:"Acme", sans-serif;font-size:24px;font-weight:bold;margin-top:20px;margin-bottom:10px;text-align:center;color:#210742}.search-bar-container.svelte-jjr1wa.svelte-jjr1wa{margin-top:20px;margin-bottom:10px;text-align:center;color:#210742}.search-bar.svelte-jjr1wa.svelte-jjr1wa{width:180px;padding:10px;margin-left:50px;border:1px solid #210742;text-align:center;border-radius:5px}.filter-button.svelte-jjr1wa.svelte-jjr1wa{background-color:#d7b594;color:#210742;border:1px solid #210742;padding:10px 20px;margin-left:10px;cursor:pointer;border-radius:5px}.article-grid.svelte-jjr1wa.svelte-jjr1wa{display:grid;grid-template-columns:repeat(3, 1fr);gap:15px}.article-link.svelte-jjr1wa.svelte-jjr1wa{text-decoration:none}.article-rectangle.svelte-jjr1wa.svelte-jjr1wa{width:350px;height:400px;min-height:300px;background-color:rgba(
      222,
      228,
      229,
      0.7
    );color:#000;margin-left:auto;margin-right:auto;border-radius:5%;box-shadow:7px 7px 10px rgba(0, 0, 0, 0.5);padding:10px;transition:transform 0.3s}.article-rectangle.svelte-jjr1wa img.svelte-jjr1wa{width:100%;height:200px;object-fit:cover;border-radius:5%}.article-details.svelte-jjr1wa.svelte-jjr1wa{display:flex;justify-content:space-between;margin-bottom:5px}.article-headers.svelte-jjr1wa.svelte-jjr1wa{margin-top:15px}.category.svelte-jjr1wa.svelte-jjr1wa{background-color:#d9534f;color:#fff;border-radius:10px;padding:7px;min-width:60px;text-transform:uppercase;font-size:14px;text-align:center;font-family:"Acme", sans-serif;font-weight:bold}.world.svelte-jjr1wa.svelte-jjr1wa{background-color:rgb(102, 87, 0)}.lore.svelte-jjr1wa.svelte-jjr1wa{background-color:rgb(1, 41, 114)}.games.svelte-jjr1wa.svelte-jjr1wa{background-color:rgb(0, 71, 0)}.streams.svelte-jjr1wa.svelte-jjr1wa{background-color:rgb(91, 91, 91)}.novels.svelte-jjr1wa.svelte-jjr1wa{background-color:purple}.personal.svelte-jjr1wa.svelte-jjr1wa{background-color:rgb(123, 0, 0)}.current-button.svelte-jjr1wa.svelte-jjr1wa{background-color:#210742;color:white;border:none;padding:10px;cursor:default;border-radius:5px}.pagination.svelte-jjr1wa.svelte-jjr1wa{margin-top:50px;text-align:center}.page-button.svelte-jjr1wa.svelte-jjr1wa{padding:10px 20px;margin:0 7px;background-color:white;color:#210742;border:1px solid #210742;border-radius:15px;transition:background-color 0.3s ease,
      color 0.3s ease;cursor:pointer}.prev-page.svelte-jjr1wa.svelte-jjr1wa,.next-page.svelte-jjr1wa.svelte-jjr1wa{margin:0 5px}.ellipsis.svelte-jjr1wa.svelte-jjr1wa{padding:10px 15px;color:#210742}@media(max-width: 568px){.colored-rectangle.svelte-jjr1wa.svelte-jjr1wa{width:100%;margin:0;padding:15px}.article-grid.svelte-jjr1wa.svelte-jjr1wa{display:grid;grid-template-columns:repeat(
        1,
        1fr
      );gap:10px}.article-rectangle.svelte-jjr1wa.svelte-jjr1wa{width:100%;min-height:200px}.search-bar.svelte-jjr1wa.svelte-jjr1wa{width:100%;margin-left:0;margin-bottom:10px}.filter-button.svelte-jjr1wa.svelte-jjr1wa{width:100%;margin-left:0}}