.colored-rectangle.svelte-4zunyj.svelte-4zunyj{width:95%;margin:10px auto;padding:30px;background-color:rgba(
      215,
      181,
      148,
      0.9
    );border:5px solid rgba(188, 151, 96, 1);min-height:80%;border-radius:15px;font-family:"Acme", sans-serif;box-shadow:0 4px 20px rgba(0, 0, 0, 0.7);background-image:url("https://res.cloudinary.com/dw39fffyv/image/upload/v1742167495/papertexture_we5bse.jpg");background-size:cover;background-position:center}.row.svelte-4zunyj.svelte-4zunyj{display:flex;margin-top:50px;margin-bottom:10px}.row-container.svelte-4zunyj.svelte-4zunyj{width:33.33%}.section-title.svelte-4zunyj.svelte-4zunyj{font-family:"Acme", sans-serif;font-size:24px;font-weight:bold;margin-top:20px;margin-bottom:10px;text-align:center;color:#210742}.search-bar-container.svelte-4zunyj.svelte-4zunyj{margin-top:20px;margin-bottom:10px;text-align:center;color:#210742}.search-bar.svelte-4zunyj.svelte-4zunyj{width:180px;padding:10px;margin-left:50px;border:1px solid #210742;text-align:center;border-radius:5px}.filter-button.svelte-4zunyj.svelte-4zunyj{background-color:rgba(215, 181, 148, 0.9);color:#210742;border:1px solid #210742;padding:10px 20px;margin-left:10px;cursor:pointer;border-radius:5px}.filter-button.svelte-4zunyj.svelte-4zunyj:hover{background-color:rgba(188, 151, 96, 1)}.story-grid.svelte-4zunyj.svelte-4zunyj{display:grid;grid-template-columns:repeat(3, 1fr);gap:15px}.story-link.svelte-4zunyj.svelte-4zunyj{text-decoration:none}.story-rectangle.svelte-4zunyj.svelte-4zunyj{width:325px;height:200px;background-color:rgba(
      222,
      228,
      229,
      0.7
    );color:#000;margin-left:auto;margin-right:auto;border-radius:5%;box-shadow:7px 7px 10px rgba(0, 0, 0, 0.5);padding:10px}.story-details.svelte-4zunyj.svelte-4zunyj{display:flex;flex-direction:column;margin-left:5px}.category.svelte-4zunyj.svelte-4zunyj{background-color:#d9534f;color:#fff;border-radius:10px;padding:7px;min-width:60px;text-transform:uppercase;font-size:10px;text-align:center;font-family:"Acme", sans-serif}.published-date.svelte-4zunyj.svelte-4zunyj{font-weight:bold;font-size:14px;color:#661778;margin-top:5px}.story-headers.svelte-4zunyj.svelte-4zunyj{font-weight:bold;margin-top:15px;text-align:center;color:#210742}.excerpt.svelte-4zunyj.svelte-4zunyj{font-size:14px;margin-top:5px;margin-left:10px;color:#333}.pagination.svelte-4zunyj.svelte-4zunyj{margin-top:50px;text-align:center}.page-button.svelte-4zunyj.svelte-4zunyj{padding:10px 20px;margin:0 7px;background-color:white;color:#210742;border:1px solid #210742;border-radius:15px;transition:background-color 0.3s ease,
      color 0.3s ease;cursor:pointer}.current-button.svelte-4zunyj.svelte-4zunyj{padding:10px 20px;margin:0 7px;background-color:blueviolet;color:#fff;border:1px solid blueviolet;border-radius:15px;transition:background-color 0.3s ease,
      color 0.3s ease;cursor:pointer}.pagination.svelte-4zunyj button.svelte-4zunyj:hover{background-color:black;color:white}.ellipsis.svelte-4zunyj.svelte-4zunyj{color:#210742}@media(max-width: 1400px){.search-bar.svelte-4zunyj.svelte-4zunyj{width:120px}.story-grid.svelte-4zunyj.svelte-4zunyj{display:grid;grid-template-columns:repeat(2, 1fr);gap:10px}}@media(max-width: 950px){.search-bar.svelte-4zunyj.svelte-4zunyj{width:100px}.filter-button.svelte-4zunyj.svelte-4zunyj{padding:10px 20px}.story-grid.svelte-4zunyj.svelte-4zunyj{display:grid;grid-template-columns:repeat(1, 1fr);gap:10px}}@media(max-width: 900px){.search-bar.svelte-4zunyj.svelte-4zunyj{width:80px}.filter-button.svelte-4zunyj.svelte-4zunyj{padding:10px 20px}}@media(max-width: 768px){.search-bar.svelte-4zunyj.svelte-4zunyj{width:70px}.filter-button.svelte-4zunyj.svelte-4zunyj{padding:10px 10px}}@media(max-width: 568px){.story-grid.svelte-4zunyj.svelte-4zunyj{display:grid;grid-template-columns:repeat(1, 1fr);gap:10px}.story-rectangle.svelte-4zunyj.svelte-4zunyj{width:250px}.row.svelte-4zunyj.svelte-4zunyj{display:flex;flex-direction:column;align-items:center;justify-content:center}.search-bar.svelte-4zunyj.svelte-4zunyj{width:50px}.filter-button.svelte-4zunyj.svelte-4zunyj{padding:10px 5px}}