.colored-rectangle.svelte-ighd5n.svelte-ighd5n{width:95%;margin:10px auto;padding:30px;background-color:rgba(
      215,
      181,
      148,
      0.9
    );border:5px solid rgba(188, 151, 96, 1);min-height:80%;border-radius:15px;font-family:"Acme", sans-serif;color:#333;line-height:1.5;box-shadow:0 4px 20px rgba(0, 0, 0, 0.7);overflow:hidden;background-image:url("/media/images/papertexture.jpeg");background-size:cover;background-position:center}.colored-rectangle.svelte-ighd5n h2.svelte-ighd5n{color:#210742}.colored-rectangle.svelte-ighd5n p.svelte-ighd5n{color:#210742}.row.svelte-ighd5n.svelte-ighd5n{display:flex;margin-top:50px;margin-bottom:10px;align-items:center}.row-container.svelte-ighd5n.svelte-ighd5n{width:33.33%}.section-title.svelte-ighd5n.svelte-ighd5n{font-family:"Acme", sans-serif;font-size:24px;font-weight:bold;margin-top:20px;margin-bottom:10px;text-align:center;color:#210742}.search-bar-container.svelte-ighd5n.svelte-ighd5n{margin-top:20px;margin-bottom:10px;text-align:center;color:#210742}.search-bar.svelte-ighd5n.svelte-ighd5n{width:180px;padding:10px;margin-left:50px;border:1px solid #210742;text-align:center;border-radius:5px}.filter-button.svelte-ighd5n.svelte-ighd5n{background-color:#d7b594;color:#210742;border:1px solid #210742;padding:10px 20px;margin-left:10px;cursor:pointer;border-radius:5px}.story-grid.svelte-ighd5n.svelte-ighd5n{display:grid;grid-template-columns:repeat(3, 1fr);gap:15px}.story-link.svelte-ighd5n.svelte-ighd5n{text-decoration:none}.story-rectangle.svelte-ighd5n.svelte-ighd5n{width:325px;height:200px;background-color:rgba(
      222,
      228,
      229,
      0.7
    );color:#000;margin-left:auto;margin-right:auto;border-radius:5%;box-shadow:7px 7px 10px rgba(0, 0, 0, 0.5);padding:10px}.story-details.svelte-ighd5n.svelte-ighd5n{display:flex;align-items:center;margin-left:5px}.category.svelte-ighd5n.svelte-ighd5n{background-color:#d9534f;color:#fff;border-radius:10px;padding:7px;min-width:60px;text-transform:uppercase;font-size:10px;text-align:center;font-family:"Acme", sans-serif}.published-date.svelte-ighd5n.svelte-ighd5n{font-weight:bold;font-size:14px;margin-left:5px;margin-top:5px;color:#661778}.story-headers.svelte-ighd5n.svelte-ighd5n{font-weight:bold;margin-top:25px;text-align:center;color:#210742}.excerpt.svelte-ighd5n.svelte-ighd5n{font-size:14px;margin-top:5px;margin-left:10px;color:#210742}.pagination.svelte-ighd5n.svelte-ighd5n{margin-top:50px;text-align:center}.page-button.svelte-ighd5n.svelte-ighd5n{padding:10px 20px;margin:0 7px;background-color:white;color:#210742;border:1px solid #210742;border-radius:15px;transition:background-color 0.3s ease,
      color 0.3s ease;cursor:pointer}.current-button.svelte-ighd5n.svelte-ighd5n{padding:10px 20px;margin:0 7px;background-color:blueviolet;color:#fff;border:none;border-radius:15px;transition:background-color 0.3s ease,
      color 0.3s ease;cursor:pointer}.pagination.svelte-ighd5n button.svelte-ighd5n:hover{background-color:black;color:white}.ellipsis.svelte-ighd5n.svelte-ighd5n{color:white}@media(max-width: 1400px){.search-bar.svelte-ighd5n.svelte-ighd5n{width:120px}.story-grid.svelte-ighd5n.svelte-ighd5n{display:grid;grid-template-columns:repeat(2, 1fr);gap:10px}}@media(max-width: 950px){.search-bar.svelte-ighd5n.svelte-ighd5n{width:130px;padding:10px;margin-left:30px}.filter-button.svelte-ighd5n.svelte-ighd5n{padding:10px 20px;margin-left:10px}.story-grid.svelte-ighd5n.svelte-ighd5n{display:grid;grid-template-columns:repeat(1, 1fr);gap:10px}}@media(max-width: 900px){.search-bar.svelte-ighd5n.svelte-ighd5n{width:120px;padding:10px;margin-left:10px}.filter-button.svelte-ighd5n.svelte-ighd5n{padding:10px 20px;margin-left:5px}}@media(max-width: 768px){.search-bar.svelte-ighd5n.svelte-ighd5n{width:100px;padding:10px;margin-left:10px}.filter-button.svelte-ighd5n.svelte-ighd5n{padding:10px 10px;margin-left:0px}}@media(max-width: 568px){.story-grid.svelte-ighd5n.svelte-ighd5n{display:grid;grid-template-columns:repeat(1, 1fr);gap:10px}.story-rectangle.svelte-ighd5n.svelte-ighd5n{width:300px;height:200px}.row.svelte-ighd5n.svelte-ighd5n{display:flex;flex-direction:column;align-items:center;justify-content:center}.search-bar.svelte-ighd5n.svelte-ighd5n{width:70px;padding:10px;margin-left:0px}.filter-button.svelte-ighd5n.svelte-ighd5n{padding:10px 5px;margin-left:0px}}