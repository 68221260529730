/* global.css */

/* Reset styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Global body styles */
body {
    background-color: #210742;
    color: #ffffff;
    overflow-x: hidden;
    font-family: 'Roboto', sans-serif;
}